import { SitePage } from 'app/layout';
import { Contact } from 'app/contact';

export default function ContactPage() {
  return (
    <SitePage>
      <Contact />
    </SitePage>
  );
}
